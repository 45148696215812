import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  DEFAULT_SUPPORT_STEPS,
  FEATURES,
  SUPPORT_STEP_ASSIGNEE_TYPES,
  SUPPORT_STEP_TYPES,
  SUPPORT_STEP_KEYWORDS,
} from 'src/app/constants';
import { CollaboratorType } from 'src/models';
import { ApiService } from 'src/services/api.service';
import { AuthService } from 'src/services/auth.service';
import { CollaboratorTypesService } from 'src/services/collaborator-types.service';
import { PRIORITY_OPTIONS, SearchablePopoverService } from 'src/services/searchable-popover.service';
import { SlackService } from 'src/services/slack.service';

import { addConditionalRule, supportsConditionalRules } from './common';

const ASSIGNEE_LOOKUP_KEYS = {
  [SUPPORT_STEP_ASSIGNEE_TYPES.USER]: 'assigneeId',
  [SUPPORT_STEP_ASSIGNEE_TYPES.TEAM]: 'groupId',
  [SUPPORT_STEP_ASSIGNEE_TYPES.GROUP]: 'userGroupId',
};

@Component({
  selector: 'app-support-steps',
  templateUrl: './support-steps.component.html',
  styleUrls: ['./support-steps.component.scss'],
})
export class SupportStepsComponent implements OnInit {
  @Input() initialSupportSteps: any[] = [];
  @Input() isDefaults = false;
  @Input() disabled = false;
  @Input() createdBy;

  @Output() didChange = new EventEmitter<any[]>();

  isLoading = true;
  channels: any[];

  assigneeLookups: any = {
    [SUPPORT_STEP_KEYWORDS.CREATED_BY_USER]: {
      id: SUPPORT_STEP_KEYWORDS.CREATED_BY_USER,
      displayName: 'User who created customer',
    },
    [SUPPORT_STEP_KEYWORDS.PRIMARY_SUPPORT_ASSIGNEE]: {
      id: SUPPORT_STEP_KEYWORDS.PRIMARY_SUPPORT_ASSIGNEE,
      displayName: 'Primary support rep',
    },
    [SUPPORT_STEP_KEYWORDS.SECONDARY_SUPPORT_ASSIGNEE]: {
      id: SUPPORT_STEP_KEYWORDS.SECONDARY_SUPPORT_ASSIGNEE,
      displayName: 'Secondary support rep',
    },
    [SUPPORT_STEP_KEYWORDS.ASSIGNEE_MANAGER]: {
      id: SUPPORT_STEP_KEYWORDS.ASSIGNEE_MANAGER,
      displayName: "Assignee's manager",
    },
    [SUPPORT_STEP_KEYWORDS.SUBMITTER_MANAGER]: {
      id: SUPPORT_STEP_KEYWORDS.SUBMITTER_MANAGER,
      displayName: "Submitter's manager",
    },
  };
  supportSteps: any[] = [];
  isMultiStepDisabled = false;
  lookups: Record<string, Record<string, string>> = {};
  hasManageChannelsScope = false;
  collaboratorTypes: Partial<CollaboratorType>[] = [];
  isSlackDisconnected = false;

  SUPPORT_STEP_KEYWORDS = SUPPORT_STEP_KEYWORDS;

  constructor(
    public authService: AuthService,
    private apiService: ApiService,
    private slackService: SlackService,
    private searchablePopoverService: SearchablePopoverService,
    private collaboratorTypesService: CollaboratorTypesService,
  ) {}

  async ngOnInit() {
    this.isLoading = true;

    await Promise.all([this.loadCollaboratorTypes(), this.loadConnectedChannels()]);

    this.hasManageChannelsScope = await this.slackService.hasScope('channels:manage');

    this.supportSteps = (
      this.initialSupportSteps?.length
        ? this.initialSupportSteps.map((s) => {
            if (
              [SUPPORT_STEP_TYPES.ASSIGNMENT, SUPPORT_STEP_TYPES.ESCALATION].includes(s.type) &&
              s.userId != null &&
              s.assigneeId == null
            ) {
              s.assigneeId = s.userId;
              s.assigneeType = SUPPORT_STEP_ASSIGNEE_TYPES.USER;
            }

            return s;
          })
        : DEFAULT_SUPPORT_STEPS
    ).map((s) => {
      if (!this.isDefaults && this.createdBy && s.assigneeId === SUPPORT_STEP_KEYWORDS.CREATED_BY_USER) {
        s.assigneeId = this.createdBy;
      }

      return s;
    });

    await this.loadLookups();

    if (!this.initialSupportSteps?.length) {
      this.didChange.emit(this.supportSteps);
    }

    this.isLoading = false;
    this.isMultiStepDisabled = this.authService.restrictedFeatures.includes(FEATURES.MULTISTEP_ASSIGNMENT_RULES);
  }

  async loadCollaboratorTypes() {
    const collaboratorTypes = await this.collaboratorTypesService.list({
      order: ['name'],
    });

    this.collaboratorTypes = collaboratorTypes.data;
  }

  async loadLookups() {
    const lookupIds: Record<string, string[]> = {};

    function addLookupId(field: string, value: string) {
      if (!value) {
        return;
      }

      if (!lookupIds[field]) {
        lookupIds[field] = [];
      }

      // For some reason, we always get the "team" key whether it's a Slack team or a user group
      if (field === ASSIGNEE_LOOKUP_KEYS[SUPPORT_STEP_ASSIGNEE_TYPES.TEAM]) {
        if (!lookupIds['userGroupId']) {
          lookupIds['userGroupId'] = [];
        }

        lookupIds['userGroupId'].push(value);
      }

      lookupIds[field].push(value);
    }

    for (const supportStep of this.supportSteps) {
      addLookupId(ASSIGNEE_LOOKUP_KEYS[supportStep.assigneeType], supportStep.assigneeId);
      // addLookupId('slackChannelId', supportStep.channelId);

      if (supportStep.branches?.length) {
        for (const branch of supportStep.branches) {
          addLookupId(ASSIGNEE_LOOKUP_KEYS[branch.assigneeType], branch.assigneeId);
          // addLookupId('slackChannelId', branch.channelId);

          if (branch.conditions?.length) {
            for (const condition of branch.conditions) {
              addLookupId(condition.field, condition.value);
            }
          }
        }
      }
    }

    if (Object.keys(lookupIds).length) {
      this.lookups = {
        ...(await this.apiService.postPromise('/saved-filters/get-lookup-values', lookupIds)),
        priority: PRIORITY_OPTIONS.reduce(
          (acc, p) => {
            acc[p.id] = p.title;

            return acc;
          },
          {} as Record<string, string>,
        ),
      };

      // Put any users, groups, or teams into the assigneeLookups
      const assigneeKeys = [
        ASSIGNEE_LOOKUP_KEYS[SUPPORT_STEP_ASSIGNEE_TYPES.USER],
        ASSIGNEE_LOOKUP_KEYS[SUPPORT_STEP_ASSIGNEE_TYPES.TEAM],
        ASSIGNEE_LOOKUP_KEYS[SUPPORT_STEP_ASSIGNEE_TYPES.GROUP],
      ];

      for (const assigneeKey of assigneeKeys) {
        if (this.lookups[assigneeKey]) {
          for (const [key, value] of Object.entries(this.lookups[assigneeKey])) {
            this.assigneeLookups[key] = {
              id: key,
              displayName:
                assigneeKey === ASSIGNEE_LOOKUP_KEYS[SUPPORT_STEP_ASSIGNEE_TYPES.TEAM] ||
                assigneeKey === ASSIGNEE_LOOKUP_KEYS[SUPPORT_STEP_ASSIGNEE_TYPES.GROUP]
                  ? `${value} (group)`
                  : value,
            };
          }
        }
      }
    }
  }

  async loadConnectedChannels() {
    try {
      this.channels = await this.slackService.getConnectedChannels();
    } catch (e) {
      if (e.status === 500) {
        this.isSlackDisconnected = true;
      }
    }
  }

  removeSupportStep(i: number) {
    this.supportSteps.splice(i, 1);

    this.didChange.emit(this.supportSteps);
  }

  addSupportStep() {
    if (this.supportSteps?.length) {
      this.supportSteps.push({
        type: 'reminder',
        minutes: 60,
      });
    } else {
      this.supportSteps.push({
        type: 'assignment',
        assigneeId: null,
        assigneeType: null,
      });
    }

    this.didChange.emit(this.supportSteps);
  }

  onReorder({ detail }) {
    const { from, to } = detail;

    this.supportSteps.splice(to + 1, 0, this.supportSteps.splice(from + 1, 1)[0]);

    detail.complete();

    this.didChange.emit(this.supportSteps);
  }

  showAssigneeOptions(supportStep: any) {
    this.searchablePopoverService.assignees({
      event,
      selectedId: supportStep.assigneeId,
      showCreatedByUserOption: true,
      showPrimaryRepOption: true,
      showNoneOption: true,
      callback: async (id: string, name: string, object: any) => {
        supportStep.assigneeId = id;
        supportStep.assigneeType = object?.type ?? SUPPORT_STEP_ASSIGNEE_TYPES.USER;
        this.assigneeLookups[id] = {
          id,
          displayName: name,
        };
      },
    });
  }

  showSlackEntityOptions(supportStep: any) {
    this.searchablePopoverService.slackEntities({
      event,
      selectedId: supportStep.assigneeId,
      showCreatedByUserOption: true,
      showPrimaryRepOption: true,
      showNoneOption: true,
      callback: async (id: string, _name: string, object: any) => {
        supportStep.assigneeId = id;
        supportStep.assigneeType = object?.type ?? SUPPORT_STEP_ASSIGNEE_TYPES.USER;
        this.assigneeLookups[id] = {
          id,
          displayName: name,
        };
      },
    });
  }

  channelCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === null);
  }

  saveOnChange() {
    this.didChange.emit(this.supportSteps);
  }

  createChannelCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === false);
  }

  remainOnCurrentSteptIfNoAssigneeFoundFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === false);
  }

  createChannelIsPrivateCompareFn(v1, v2) {
    return v1 === v2 || (v1 === '' && v2 === true);
  }

  addConditionalRule(i: number) {
    return addConditionalRule(this.supportSteps[i]);
  }

  supportsConditionalRules(i: number) {
    return supportsConditionalRules(this.supportSteps[i]);
  }
}
